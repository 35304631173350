import { CamelCasedResumeTable, PageSizeName } from '@/lib/types.ts'
import { addStylesToHtml } from '@/lib/utils'
import supabase from '@/lib/utils/supabase'
import { usePostHog } from 'posthog-js/react'
import { useState } from 'react'

export const useDownloadResumePDF = (resume: CamelCasedResumeTable, editorHtml: string) => {
  const [isDownloading, setIsDownloading] = useState(false)
  const posthog = usePostHog()

  const fileName = `${resume.fileName}.pdf`

  const downloadResumePDF = async () => {
    setIsDownloading(true)
    try {
      posthog.capture('clicked_download_resume', {
        resume_id: resume.id,
        resume_name: resume.fileName,
        page_size: resume.pageSize,
      })

      const { data, error } = await supabase.functions.invoke('download-resume', {
        body: {
          html: addStylesToHtml(editorHtml, resume.pageSize as PageSizeName),
          // test: !IN_PROD_ENV,
        },
      })

      if (error) {
        throw new Error(`Error invoking Edge Function: ${error.message}`)
      }

      const blob = new Blob([data], { type: 'application/pdf' })
      const downloadUrl = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = downloadUrl
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(downloadUrl)
    } catch (error) {
      console.error('Error downloading PDF with Edge Function:', error)
    } finally {
      setIsDownloading(false)
    }
  }

  return { downloadResumePDF, isDownloading }
}
