import { TEMPLATE_STYLES } from '@/lib/constants/template-styles'
import { TemplateName } from '@/lib/types'

/**
 * Applies template-specific styles to a DOM element
 * @param element DOM element to apply styles to
 * @param templateName Name of the template
 */
export const applyTemplateStyles = (element: HTMLElement, templateName: TemplateName): void => {
  const templateStyle = TEMPLATE_STYLES[templateName]

  element.style.setProperty('--font-family', templateStyle.fontFamily)
  element.style.setProperty('--text-accent-color', templateStyle.textAccentColor)
} 