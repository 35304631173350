import Loading from '@/components/ui/Loading'
import useUserProfileQueries from '@/hooks/react-query/use-user-profile-queries'
import { useSession } from '@/hooks/use-session'
import { CamelCasedUserProfileTable } from '@/lib/types'
import { checkWaitlistStatus, isApproved, isWhitelistedDomain, WaitlistSignupResponse } from '@/lib/waitlist'
import { Session } from '@supabase/supabase-js'
import { createContext, useContext, useEffect, useState } from 'react'

type UserContextType = {
  session: Session | null
  waitlistStatus: WaitlistSignupResponse | null
  userProfile: CamelCasedUserProfileTable | null
}

export const UserContext = createContext<UserContextType | null>(null)

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const session = useSession()
  const [waitlistStatus, setWaitlistStatus] = useState<WaitlistSignupResponse | null>(null)
  const [isWaitlistLoading, setIsWaitlistLoading] = useState(false)
  const userEmail = session?.user?.email
  const userId = session?.user?.id

  const { useUserProfileQuery } = useUserProfileQueries()
  const userProfileQuery = useUserProfileQuery(userId)

  useEffect(() => {
    const fetchWaitlistStatus = async () => {
      if (!userEmail) {
        setWaitlistStatus(null)
        return
      }

      setIsWaitlistLoading(true)
      try {
        const status = await checkWaitlistStatus(userEmail, true)
        setWaitlistStatus(status)
      } finally {
        setIsWaitlistLoading(false)
      }
    }

    fetchWaitlistStatus()
  }, [userEmail])

  // isLoading instead of isPending here because isPending checks if data is available but data may be null
  if (isWaitlistLoading || userProfileQuery.isLoading) {
    return <Loading />
  }

  return (
    <UserContext.Provider
      value={{
        session,
        waitlistStatus,
        userProfile: userProfileQuery.data ?? null,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useCurrentUser = () => {
  const context = useContext(UserContext)
  if (!context?.session) throw new Error('No session found')

  return context.session.user
}

export const useUserProfile = () => {
  const context = useContext(UserContext)

  return context?.userProfile
}

export const useIsUserApprovedOnWaitlist = () => {
  const context = useContext(UserContext)

  const waitlistStatus = context?.waitlistStatus
  const userEmail = context?.session?.user?.email

  if (!waitlistStatus || !userEmail) return false

  return isApproved(waitlistStatus) || isWhitelistedDomain(userEmail)
}

export const useHasCompletedOnboarding = () => {
  const context = useContext(UserContext)
  const userEmail = context?.session?.user?.email

  // If user has a whitelisted domain, consider onboarding as completed
  if (userEmail && isWhitelistedDomain(userEmail)) {
    return true
  }

  return !!context?.userProfile?.onboardingCompletedAt
}
