import { toCamelCase } from '@/lib/utils'
import supabase from '@/lib/utils/supabase'
import { queryOptions, skipToken, useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

const fetchSharedResume = async (shareId: string) => {
  try {
    const { data, error } = await supabase.rpc('get_resume_by_share_id', {
      input_share_id: shareId,
    })
    if (error) throw error

    return toCamelCase(data)
  } catch (error) {
    if (error instanceof Error && error.message === 'Resume not found') {
      throw new Error('Resume not found')
    }
    throw error
  }
}

export const queryFactory = {
  sharedResume: (shareId: string | undefined) =>
    queryOptions({
      queryKey: ['shared_resume', shareId],
      queryFn: shareId ? () => fetchSharedResume(shareId) : skipToken,
    }),
}

export const useShareResumeQueries = () => {
  const { shareId: paramShareId } = useParams()

  const useSharedResumeQuery = (overrideShareId?: string) => {
    const shareId = overrideShareId || paramShareId
    return useQuery(queryFactory.sharedResume(shareId))
  }

  return { useSharedResumeQuery }
}

export const useGetDubLinkStats = (dubLinkId: string | null) => {
  return useQuery({
    queryKey: ['dubLinkStats', dubLinkId],
    queryFn: async () => {
      if (!dubLinkId) return null

      const { data, error } = await supabase.functions.invoke('get-dub-link-stats', {
        body: { linkId: dubLinkId }
      })

      if (error) throw error
      return data
    },
  })
}
