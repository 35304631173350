import { TemplateName } from '@/lib/types'

export interface TemplateStyle {
  fontFamily: string
  textAccentColor: string
}

export const TEMPLATE_STYLES: Record<TemplateName, TemplateStyle> = {
  modern: {
    fontFamily: "'Roboto', sans-serif",
    textAccentColor: '#0395de',
  },
  classic: {
    fontFamily: "'Computer Modern', serif",
    textAccentColor: '#000000',
  },
} 